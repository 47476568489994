const locale = navigator.language

export const formatNumber = (value: number, currency: string): string => {
  if (isNaN(value)) return `${value} ${currency}`

  const formattedValue = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 15,
    // currency,
    // style: 'currency',
    currencyDisplay: 'code',
  }).format(value)

  return formattedValue
}

export const getLocale = () => locale.split('-')[0]
