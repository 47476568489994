import { CheckoutSdkOptions } from '@mangopay/checkout-sdk/types/checkout-sdk-elements-core/checkout-sdk-options'
import { MangoPayInStatus } from 'assets/const'
import { SelectOption } from 'components/CustomInputSelect'

export type MangoDictionary = {
    clientId: string
    cardTypeList: SelectOption[]
    currencyList: SelectOption[]
}

export enum UserMangoStatus {
    created = 'created',
    pending = 'pending',
    uncreated = 'uncreated',
}
export enum StatusKYC {
    KYC_NOT_VERIFIED = 'KYC_NOT_VERIFIED',
    KYC_CREATED = 'KYC_CREATED',
    KYC_FAILED = 'KYC_FAILED',
    KYC_OUTDATED = 'KYC_OUTDATED',
    KYC_SUCCEEDED = 'KYC_SUCCEEDED',
    KYC_VALIDATION_ASKED = 'KYC_VALIDATION_ASKED',
}

export enum StatusUploadFileKYC {
    // статусы загрузки файла
    KYC_UNSUPPORTED_FILE_TYPE = 'KYC_UNSUPPORTED_FILE_TYPE',
    KYC_FILE_UPLOAD_SUCCESS = 'KYC_FILE_UPLOAD_SUCCESS',
}

export const UserMangoStatusKYCText: Record<string, string> = {
    KYC_NOT_VERIFIED: 'Not Verified',
    KYC_CREATED: 'Created',
    KYC_FAILED: 'Failed',
    KYC_OUTDATED: 'Outdated',
    KYC_SUCCEEDED: 'Succeeded',
    KYC_VALIDATION_ASKED: 'Validation Asked',
}
export const KYCFailedReason: Record<string, string> = {
    DOCUMENT_DO_NOT_MATCH_USER_DATA: 'Document does not match user data',
    DOCUMENT_NOT_ACCEPTED: 'Document not accepted',
    DOCUMENT_UNREADABLE: 'Document unreadable',
    DOCUMENT_HAS_EXPIRED: 'Document has expired',
    DOCUMENT_INCOMPLETE: 'Document incomplete',
    DOCUMENT_MISSING: 'Document missing',
    UNDERAGE_PERSON: 'Underage person',
    SPECIFIC_CASE: 'Specific case',
    DOCUMENT_FALSIFIED: 'Document falsified',
}

export type MangoCardRequest = {
    mangoUserId: string
    currency: string
    cardType: string
}

export type PayinMangoCardRequest = {
    invoiceId: string
    cardType: string
    cardId: string
    acceptHeader: string
    javaEnabled: boolean
    javascriptEnabled: boolean
    language: string
    colorDepth: number
    screenHeight: number
    screenWidth: number
    timeZoneOffset: number
    userAgent: string
    secureModeReturnURL: string
}

export interface MangoCardResponse {
    accessKey: string
    cardRegistrationId: string
    cardRegistrationUrl: string
    cardType: string
    currency: string
    preregistrationData: string
    status: string
    userId: string
    message?: string
    error?: string
}
export interface ServiceErrorResponse {
    message: string
    error: string
}
export interface MangoKycInfo {
    mangoKycDocumentId: string
    mangoUserId: string
    status: string
    fileUploadStatus?: string
    reason?: string
}

export enum SdkStatuses {
    NOT_READY = 'NOT_READY',
    READY = 'READY',
    NOT_INITIALIZED = 'NOT_INITIALIZED',
}

export interface SdkOptionsData {
    status: SdkStatuses
    message?: string
    data: CheckoutSdkOptions | null
}

export interface ReadySdkOptionsData extends SdkOptionsData {
    status: SdkStatuses.READY
    data: CheckoutSdkOptions // data не может быть null
}

export function IsReadySdkOptionsData(
    options: SdkOptionsData
): options is ReadySdkOptionsData {
    return options.status === SdkStatuses.READY && options.data !== null
}

export interface MangoKYCRequest {
    mangoKycDocumentId: string
    file: string
    pageNum: number
}

export interface MangoNaturalProfile {
    birthday: string
    nationality: string
    countryOfResidence: string
    address?: Address
}

export interface MangoLegalProfile {
    companyName: string
    companyNumber: string
    type: string
    companyAddress?: Address
    email: string
    legalRepresentativeBirthday: string
    legalRepresentativeAddress?: Address
    legalRepresentativeNationality: string
    legalRepresentativeCountryOfResidence: string
}

export interface CreateMangoLegalProfileRequest {
    companyName: string
    personType: string
    isTermsAndConditionsAccepted: boolean
}

interface Address {
    addressLine1?: string
    addressLine2?: string
    city?: string
    region?: string
    postalCode?: string
    country?: string
}

export interface MangoBankAccountRequest {
    tag: string
    sessionId?: string
    address: Address
}

export interface MangoBankAccountIbanRequest extends MangoBankAccountRequest {
    iban: string
    bic: string
}

export interface MangoBankAccountUsRequest extends MangoBankAccountRequest {
    accountNumber: string
    depositAccountType: string
    aba: string
}

export interface MangoBankAccountCaRequest extends MangoBankAccountRequest {
    accountNumber: string
    institutionNumber: string
    branchCode: string
    bankName: string
}

export interface MangoBankAccountGbRequest extends MangoBankAccountRequest {
    accountNumber: string
    sortCode: string
}

export interface MangoBankAccountOtherRequest extends MangoBankAccountRequest {
    accountNumber: string
    bic: string
    countryCode: string
}

export type MangoBankAccountsTypes = 'OTHER' | 'IBAN' | 'US' | 'CA' | 'GB'

export interface MangoBankAccount extends MangoBankAccountRequest {
    mangoUserId: string
    bankAccountId: string
    creationDate: string
    isActive: boolean
    ownerName: string
    type: MangoBankAccountsTypes
    iban?: string
    bic?: string
    accountNumber?: string
    depositAccountType?: string
    aba?: string
    institutionNumber?: string
    branchCode?: string
    bankName?: string
    sortCode?: string
    countryCode?: string
}

export type MangoPayInStatusType = keyof typeof MangoPayInStatus
