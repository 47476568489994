import Button from 'components/Button'
import FileDropzone from 'components/FileDropzone'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Typography from 'components/Typography'
import { useEffect, useMemo, useState } from 'react'

import { PageHeading } from 'assets/const'
import { useMatchMedia } from 'hooks/useMatchMedia'
import { toast } from 'react-toastify'
import Preloader from '../../../components/Preloader'
import { useCreateKYC, useMangoKycInfo } from '../../../hooks/data/useMangoApi'
import { useUserProfile } from '../../../hooks/data/useUserProfile'
import { MangoService } from '../../../services/mango.service'
import { useSseStore } from '../../../store/useSseStore'
import {
    KYCFailedReason,
    StatusKYC,
    StatusUploadFileKYC,
    UserMangoStatusKYCText,
} from '../../../types/mango'
import s from './MangoKyc.module.scss'

const uploadDocsCount = 5

const MangoKyc = () => {
    const { isTablet } = useMatchMedia()
    const [files, setFiles] = useState<File[]>([])
    const [pageNum, setPageNum] = useState<number>(1)
    const [fileErrors, setFileErrors] = useState<string[] | null>(null)
    const { startVerificationProcess } = useCreateKYC()
    const { mangoKycInfo } = useSseStore()
    const { data: profile, isLoading: isProfileLoading } = useUserProfile()
    const mangoProfile = profile?.businessSettings?.paymentSettings?.mango
    const mangoKYCStatusWithProfile = mangoProfile?.kycStatus
    const [waitingSseResponse, setWaitingSseResponse] = useState(false)
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
    const { mutate: startMangoKycQuery } = useMangoKycInfo()

    const canShowUploadButton = files.length < uploadDocsCount
    const canShowStartSession =
        mangoKYCStatusWithProfile === StatusKYC.KYC_NOT_VERIFIED ||
        mangoKYCStatusWithProfile === StatusKYC.KYC_OUTDATED ||
        mangoKYCStatusWithProfile === StatusKYC.KYC_FAILED

    const removePdfFile = (file: File) => {
        setFiles(files!.filter((pdfFile) => pdfFile !== file))
        setFileErrors(null)
        setPageNum(pageNum - 1)
    }

    const uploadFiles = (file: File[] | null) => {
        setDisabledSubmitButton(true)
        if (file?.length && mangoKycInfo?.mangoKycDocumentId) {
            const reader = new FileReader()
            reader.readAsDataURL(file[file.length - 1])
            reader.onloadend = () => {
                const base64String = reader.result as string
                MangoService.pageKYC({
                    mangoKycDocumentId: mangoKycInfo.mangoKycDocumentId,
                    file: base64String,
                    pageNum: pageNum,
                })
            }

            // console.log('uploadFiles', file, files, pageNum)
            setFiles(file)
            setPageNum(pageNum + 1)
        }
    }

    useEffect(() => {
        if (!files.length) {
            setDisabledSubmitButton(true)
        }
    }, [files])

    useEffect(() => {
        // при первом входе если нет mangoKycDocumentId и сессия создана - сразу вызываем info
        // и для FAILED чтоб получить причину ошибки
        if (
            !mangoKycInfo?.mangoKycDocumentId &&
            (mangoKYCStatusWithProfile === StatusKYC.KYC_CREATED ||
                mangoKYCStatusWithProfile === StatusKYC.KYC_FAILED)
        ) {
            startMangoKycQuery()
        }

        if (mangoKYCStatusWithProfile === StatusKYC.KYC_VALIDATION_ASKED) {
            setDisabledSubmitButton(false)
        }
    }, [mangoKYCStatusWithProfile])

    useEffect(() => {
        if (
            mangoKycInfo?.fileUploadStatus ===
            StatusUploadFileKYC.KYC_UNSUPPORTED_FILE_TYPE
        ) {
            toast.error('Unsupported error file mango')
            removePdfFile(files[files.length - 1])
        }

        if (
            mangoKycInfo?.fileUploadStatus ===
            StatusUploadFileKYC.KYC_FILE_UPLOAD_SUCCESS
        ) {
            setDisabledSubmitButton(false)
        }
    }, [mangoKycInfo])

    const handleDocumentsUpload = () => {
        setDisabledSubmitButton(true)
        MangoService.submitKYC(mangoKycInfo?.mangoKycDocumentId)
        // console.log(files)
    }

    const startKYCSession = () => {
        setWaitingSseResponse(true)
        startVerificationProcess()
    }

    const getTxtColorCls = useMemo(() => {
        let color: string | undefined
        switch (mangoKYCStatusWithProfile) {
            case StatusKYC.KYC_NOT_VERIFIED:
            case StatusKYC.KYC_FAILED:
            case StatusKYC.KYC_OUTDATED:
                color = s.clrFailed
                break
            case StatusKYC.KYC_SUCCEEDED:
                color = s.clrSuccess
                break
            case StatusKYC.KYC_VALIDATION_ASKED:
                color = s.clrPending
                break

            default:
                color = s.clrDflt
                break
        }
        return color
    }, [mangoKYCStatusWithProfile])

    if (isProfileLoading) {
        return <Preloader />
    }

    return (
        <Paper>
            <PaperHeader text={PageHeading.AccountVerification} />

            <Typography variant='h3' className={s.pdy10}>
                {`${profile?.firstName} ${profile?.lastName}`}
            </Typography>

            {/*common info status*/}
            {!!mangoKYCStatusWithProfile && (
                <div className={s.pdy10}>
                    {' '}
                    Status:{' '}
                    <span className={`${getTxtColorCls}`}>
            {UserMangoStatusKYCText[mangoKYCStatusWithProfile]}
          </span>
                    {/*<NotVerifiedIcon className={s.statusIcon} />*/}
                </div>
            )}
            {/* Failed reason */}
            {mangoKYCStatusWithProfile === StatusKYC.KYC_FAILED &&
                mangoKycInfo?.reason && (
                    <div className={s.pdy10}>
                        {' '}
                        Refusal Reason:{' '}
                        <span className={s.clrFailed}>
              {KYCFailedReason[mangoKycInfo?.reason]}
            </span>
                        {/*<NotVerifiedIcon className={s.statusIcon} />*/}
                    </div>
                )}

            {/*start validation*/}
            {canShowStartSession && (
                <div className={s.pdy10}>
                    <Button
                        disabled={false}
                        onClick={startKYCSession}
                        fullWidth={!isTablet}
                        loading={waitingSseResponse}
                    >
                        Start verification process
                    </Button>
                </div>
            )}

            {/*Created Step*/}
            {mangoKycInfo?.mangoKycDocumentId &&
                (mangoKYCStatusWithProfile === StatusKYC.KYC_CREATED ||
                    mangoKYCStatusWithProfile === StatusKYC.KYC_NOT_VERIFIED) && (
                    <>
                        <Typography variant='h5'>
                            Please upload your documents. You are able to upload multiple
                            files. File size must be between 36KB and 10MB Format jpg, png,
                            pdf.
                        </Typography>

                        <FileDropzone
                            files={files}
                            setFiles={uploadFiles}
                            setFileErrors={setFileErrors}
                            fileErrors={fileErrors}
                            action={removePdfFile}
                            multiple={false}
                            maxSize={10485760}
                            minSize={36864}
                            showUploadButton={canShowUploadButton}
                        />

                        <Button
                            disabled={disabledSubmitButton}
                            onClick={handleDocumentsUpload}
                            fullWidth={!isTablet}
                            loading={files.length ? disabledSubmitButton : false}
                        >
                            Send
                        </Button>
                    </>
                )}
        </Paper>
    )
}

export default MangoKyc
