import { baseApiUrl, MangoPayInStatus, MessageType, SwrKey } from 'assets/const'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import AuthService from 'services/auth.service'
import { useSseStore } from 'store/useSseStore'
import { useSWRConfig } from 'swr'
import { MangoKycInfo, UserMangoStatus } from 'types/mango'
import { getTimeNow } from 'utils/time'
import { toastError } from 'utils/toast'
import { uuid } from 'utils/uuid'

const SSE_TIMEOUT = 600000

type Props = {
  children: JSX.Element
}

const SseProvider = ({ children }: Props) => {
  const { mutate } = useSWRConfig()
  const {
    isPendingCancellation,
    setLoading,
    setModal,
    setCountries,
    setMangoCountries,
    setMangoNaturalProfile,
    setMangoLegalProfile,
    setStripeAccountLink,
    setConnectedAccount,
    setSseErrorMessages,
    setCryptoSettings,
    setStripeSettings,
    setBankAccounts,
    setMangoBankAccounts,
    setMangoBankAccount,
    setCardAccounts,
    setIsPendingCancellation,
    setMangoUserStatus,
    setCreateMangoCardResponse,
    setMangoKycInfo,
    setCanGoToPayInMango,
    setPayInStatus,
  } = useSseStore()

  const sse = useRef<EventSourcePolyfill | null>(null)
  useEffect(() => {
    if (sse.current) {
      return
    }
    const connect = () => {
      AuthService.updateToken()
      const token = AuthService.getToken()
      sse.current = new EventSourcePolyfill(
          `${baseApiUrl}sse?sessionId=${uuid}`,
          {
            headers: { Authorization: 'Bearer ' + token },
            heartbeatTimeout: SSE_TIMEOUT,
            withCredentials: false,
          }
      )
      sse.current.onopen = (e) => {
        console.log('open', e)
      }

      sse.current.onmessage = (e) => {
        console.log(getTimeNow(), 'sse message:', e)
        const data = JSON.parse(e.data)
        // console.log(getTimeNow(), 'sse parsed:', data)
        if (
            !!data?.payload?.error ||
            (data.type === MessageType.Error && data.payload?.message)
        ) {
          console.log('error!!!!!!', data)
          setLoading(false)
          toastError(
              data.payload?.error || data.payload?.message || 'Backend error'
          )
          setSseErrorMessages({
            message: data.payload?.message,
            type: data.type,
          })
          if (data.type === MessageType.Invoice || isPendingCancellation) {
            mutate(SwrKey.Invoice).finally(() => {
              setIsPendingCancellation(false)
            })
          }
          if (data.type === MessageType.ExecuteDirectMangoCardPayIn) {
            setPayInStatus(MangoPayInStatus.FAILED)
          }
          return
        }
        switch (data.type) {
          case MessageType.Country:
            setCountries(data.payload?.countries)
            break
          case MessageType.MangoCountry:
            setMangoCountries(data.payload?.mangoCountries)
            break
          case MessageType.NaturalUser:
            setMangoNaturalProfile(data.payload)
            break
          case MessageType.LegalUser:
            setMangoLegalProfile(data.payload)
            break
          case MessageType.EditUserLegal:
            setMangoLegalProfile(data.payload)
            setModal(null)
            setLoading(false)
            mutate(SwrKey.User)
            break
          case MessageType.EditUserNatural:
            setMangoNaturalProfile(data.payload)
            setModal(null)
            setLoading(false)
            mutate(SwrKey.User)
            break
            //Mango external bank account
          case MessageType.MangoExternalBankAccountList:
            setMangoBankAccounts(data.payload)
            setLoading(false)
            break
          case MessageType.MangoExternalBankAccount:
            setMangoBankAccount(data.payload)
            break
          case MessageType.DeactivateBankAccount:
            toast.success(`External bank account successfully deleted`)
            setModal(null)
            mutate(SwrKey.MangoExternalBankAccountsService.Get)
            break
          case MessageType.CreateMangoExternalBankAccount:
            toast.info('External bank account successfully added')
            setModal(null)
            mutate(SwrKey.MangoExternalBankAccountsService.Get)
            break
          case MessageType.Account:
            setStripeAccountLink(data.payload?.link)
            setConnectedAccount(data.payload?.account)
            break
          case MessageType.Link:
            setStripeAccountLink(data.payload)
            break
          case MessageType.Operation:
            if (data.payload?.clientSecret) {
              setStripeSettings(data.payload)
            }
            if (data.payload?.address) {
              setCryptoSettings(data.payload)
            }
            break
          case MessageType.ExternalAccount.Bank:
            setBankAccounts(data.payload)
            break
          case MessageType.ExternalAccount.Card:
            toast.info('External card account successfully added or changed')
            setCardAccounts(data.payload)
            break
          case MessageType.Invoice:
            mutate(SwrKey.Invoice).finally(() => {
              setIsPendingCancellation(false)
            })
            break
          case MessageType.CreateMangoNaturalUser:
            if (data.payload.payerId) {
              setMangoUserStatus(UserMangoStatus.created)
              setModal(null)
              setLoading(false)
              mutate(
                  SwrKey.User,
                  (profile) => {
                    profile.businessSettings.paymentSettings.mango.payerId =
                        data.payload.payerId
                    return profile
                  },
                  {
                    revalidate: true,
                  }
              )
              setCanGoToPayInMango(true)
            }
            break
          case MessageType.CreateMangoLegalUser:
            if (data.payload.payerId) {
              setModal(null)
              setLoading(false)
              mutate(SwrKey.User, (profile) => {
                return profile
              })
            }
            break
          case MessageType.CreateMangoCard:
            setCreateMangoCardResponse(data.payload)
            break
          case MessageType.ExecuteDirectMangoCardPayIn:
            if (
                !data.payload?.payment ||
                data.payload.payment?.status === MangoPayInStatus.FAILED
            ) {
              toastError(data.payload?.message || 'Status Failed')
              setPayInStatus(MangoPayInStatus.FAILED)
              setSseErrorMessages({
                message: data.payload.payment?.resultMessage,
                type: data.type,
              })
              return
            }

            const secureUrl =
                data.payload?.payment?.executionDetails?.secureModeRedirectUrl
            // with 3ds
            if (secureUrl) {
              window.location.replace(secureUrl)
            } else {
              setPayInStatus(MangoPayInStatus.SUCCEEDED)
            }
            break
          case MessageType.KycDocument:
          case MessageType.SubmitDocument:
            mutate(
                SwrKey.User,
                (profile: any) => {
                  profile.businessSettings.paymentSettings.mango = {
                    ...profile.businessSettings.paymentSettings.mango,
                    kycStatus: data.payload.status,
                  }
                  return { ...profile }
                },
                {
                  revalidate: false,
                }
            )
            setMangoKycInfo(data.payload)
            break
          case MessageType.UploadKycDocument:
            // mutate(SwrKey.User, (profile)=> {

            //   profile.businessSettings.paymentSettings.mango = {...profile.businessSettings.paymentSettings.mango, kycStatus: data.payload.status}
            //   return {...profile}
            // }, {
            //   revalidate: false})
            const mangoKycInfo = useSseStore.getState()
                .mangoKycInfo as MangoKycInfo

            setMangoKycInfo({
              ...mangoKycInfo,
              fileUploadStatus: data.payload.status as string,
            })
            break
        }
      }

      sse.current.onerror = (event) => {
        console.log('error', event)
        if (sse?.current?.readyState === EventSource.CLOSED) {
          AuthService.updateToken()
          setTimeout(() => {
            connect()
          }, 0)
        }
      }
    }
    connect()
  }, [])

  return children
}

export default SseProvider
