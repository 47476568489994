import { SwrKey } from 'assets/const'
import { MangoExternalBankAccountsService } from 'services/mango-external-bank-accounts.service'
import { MangoService } from 'services/mango.service'
import { mutate, useSWRConfig } from 'swr'
import useSWR from 'swr/immutable'
import { MangoDictionary, StatusKYC } from 'types/mango'
import { ResponseSse } from 'types/response'
import { useSseStore } from '../../store/useSseStore'
import { useUserProfile } from './useUserProfile'
import {useNavigate} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {toastError} from '../../utils/toast';

export const useGetMangoDict = () => {
    return useSWR<MangoDictionary, Error>(
        SwrKey.Mango.GetDict,
        () => MangoService.getDict(),
        { revalidateOnMount: true, revalidateOnFocus: false }
    )
}

export const useCreateMangoCard = (payload: any) => {
    return useSWR<any, Error>(
        payload ? SwrKey.Mango.CreateMangoCard : null,
        () => MangoService.createMangoCard(payload),
        { revalidateOnMount: false, revalidateOnFocus: false }
    )
}

//custom hook for handle mango card create
export const useHandleMangoCardRegistration = (payload: any, clientId: any, path: any) => {
    const { data: profile} = useUserProfile()
    const { mutate } = useCreateMangoCard({
        mangoUserId: profile?.businessSettings?.paymentSettings?.mango?.payerId,
        currency: payload.currency,
        cardType: payload.cardType?.value
    })
    const navigate = useNavigate()
    const { createMangoCardResponse, setCreateMangoCardResponse } = useSseStore()
    const [processing, setProcessing] = useState(false)

    useEffect(() => {
        if (createMangoCardResponse === null ) return

        if (processing) {
            if (!createMangoCardResponse?.error) {
                navigate(path, { state: {
                        clientId,
                        cardType: payload.cardType
                    }})
            } else {
                toastError(createMangoCardResponse?.error || 'Error.Please select another card type')
            }
        }

        return () => {
            setProcessing(false)
        }

    }, [createMangoCardResponse]);

    const goForward = useCallback(()=> {
        setProcessing(true)
        mutate()
    }, [mutate])

    return {
        goForward,
        processing
    }
}

export const useMangoPaymentInfo = (
    invoiceId: string | undefined,
    isPaused?: boolean
) => {
    return useSWR<any, Error>(
        SwrKey.Mango.MangoPaymentInfo,
        () => (invoiceId ? MangoService.getMangoPaymentInfo(invoiceId) : null),
        {
            revalidateOnMount: false,
            revalidateOnFocus: false,
            refreshInterval: 5000,
            isPaused: () => !!isPaused,
        }
    )
}
export const useGetMangoCountriesSse = () => {
    return useSWR<ResponseSse, Error>(SwrKey.Mango.GetCountries, () =>
        MangoService.getMangoCountriesSse()
    )
}

export const useGetMangoNaturalProfileSse = () => {
    return useSWR<ResponseSse, Error>(SwrKey.Mango.CreateMangoLegalProfile, () =>
        MangoService.getMangoNaturalProfileSse()
    )
}

export const useGetMangoLegalProfileSse = () => {
    return useSWR<ResponseSse, Error>(SwrKey.Mango.CreateMangoLegalProfile, () =>
        MangoService.getMangoLegalProfileSse()
    )
}

//mango bank accounts
export const useGetMangoBankAccountsList = () => {
    const { setLoading } = useSseStore()
    return useSWR<ResponseSse, Error>(
        SwrKey.MangoExternalBankAccountsService.Get,
        () => (setLoading(true), MangoExternalBankAccountsService.getList()),
        { revalidateOnMount: true, revalidateOnFocus: true }
    )
}

export const useGetMangoBankAccountDetails = (id: string) => {
    return useSWR<ResponseSse, Error>(
        SwrKey.MangoExternalBankAccountsService.GetDetails,
        () => MangoExternalBankAccountsService.getDetails(id),
        { revalidateOnMount: true, revalidateOnFocus: true }
    )
}

//kyc
// export const useCreateKYC = () => {
//   return useSWR<any, Error>(
//       SwrKey.Mango.MangoCreateKYC,
//       () => MangoService.createKYC(),
//       {
//           revalidateOnMount: false,
//           revalidateOnFocus: false,
//           revalidateIfStale: false,
//           refreshInterval: 0,
//           isPaused: () => true
//       }
//   )
// }

export const useCreateKYC = () => {
    const { mutate } = useSWRConfig()

    return {
        startVerificationProcess: () =>
            mutate(SwrKey.Mango.MangoCreateKYC, () => MangoService.createKYC(), {
                revalidate: false, // отключаем автоматическое повторное получение данных
            }),
    }
}

export const useMangoKycInfo = () => {
    const { kycStatus } = useUserProfile()
    return useSWR<any, Error>(
        kycStatus !== StatusKYC.KYC_NOT_VERIFIED ? SwrKey.Mango.MangoKycInfo : null,
        () =>
            MangoService.getKycInfo().then((x) => {
                mutate(
                    SwrKey.User,
                    (profile) => {
                        profile.businessSettings.paymentSettings.mango = {
                            ...profile.businessSettings.paymentSettings.mango,
                            kycStatus: x.status,
                        }
                        return { ...profile }
                    },
                    {
                        revalidate: false,
                    }
                )

                useSseStore.getState().setMangoKycInfo({
                    mangoKycDocumentId: x.mangoDocumentId,
                    mangoUserId: x.mangoUserId,
                    status: x.status,
                    reason: x.reason,
                })
            }),
        {
            revalidateOnMount: false,
            revalidateOnFocus: false,
            refreshInterval: 20000,
        }
    )
}
