import { CryptoSettings, StripeSettings } from 'types/payment-settings'
import {
  Country,
  StripeAccountLink,
  StripeConnectedAccount,
} from 'types/user-profile'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { BankAccount, CardExternalAccount } from '../types/external-account'
import {
    MangoCardResponse,
    MangoLegalProfile,
    MangoNaturalProfile,
    MangoKycInfo,
    UserMangoStatus,
    MangoBankAccount,
    MangoPayInStatusType
} from '../types/mango';
import { MangoPayInStatus } from 'assets/const'

interface SseState {
  loading: boolean
  setLoading: (payload: boolean) => void
  modal: null | string
  setModal: (payload: null | string) => void
  countries: Country[] | undefined
  setCountries: (payload: Country[]) => void
  mangoCountries: Country[] | undefined
  setMangoCountries: (payload: Country[]) => void
  mangoNaturalProfile: MangoNaturalProfile | undefined
  setMangoNaturalProfile: (payload: MangoNaturalProfile) => void
  mangoLegalProfile: MangoLegalProfile | undefined
  setMangoLegalProfile: (payload: MangoLegalProfile) => void
  connectedAccount: StripeConnectedAccount | undefined
  setConnectedAccount: (payload: StripeConnectedAccount | undefined) => void
  stripeAccountLink: StripeAccountLink | undefined
  setStripeAccountLink: (payload: StripeAccountLink | undefined) => void
  sseErrorMessages: { message: string; type: string }[]
  setSseErrorMessages: (payload: { message: string; type: string }) => void
  cryptoSettings: CryptoSettings | undefined
  setCryptoSettings: (payload: CryptoSettings | undefined) => void
  stripeSettings: StripeSettings | undefined
  setStripeSettings: (payload: StripeSettings | undefined) => void
  clearErrorMessage: (payload: string) => void
  bankAccounts: BankAccount[]
  setBankAccounts: (payload: { list: BankAccount[] }) => void
  mangoBankAccounts: MangoBankAccount[]
  setMangoBankAccounts: (payload: { accounts: MangoBankAccount[] }) => void
  mangoBankAccount: MangoBankAccount | undefined
  setMangoBankAccount: (payload: MangoBankAccount | undefined ) => void
  cardAccounts: CardExternalAccount[]
  setCardAccounts: (payload: { list: CardExternalAccount[] }) => void
  isPendingCancellation: boolean
  setIsPendingCancellation: (payload: boolean) => void
  mangoUserStatus: UserMangoStatus
  setMangoUserStatus: (payload: UserMangoStatus) => void
  createMangoCardResponse: MangoCardResponse | null,
  setCreateMangoCardResponse: (payload: MangoCardResponse) => void,
  mangoKycInfo: MangoKycInfo | null,
  setMangoKycInfo: (payload: MangoKycInfo) => void,
  canGoToPayInMango: boolean
  setCanGoToPayInMango: (payload: boolean) => void
  payInStatus: MangoPayInStatusType
  setPayInStatus: (payload: MangoPayInStatusType) => void
}

export const useSseStore = create<SseState>()(
  devtools((set, get: () => SseState) => ({
    loading: false,
    setLoading: (payload) => set({ loading: payload }),
    modal: null,
    setModal: (payload) => set({ modal: payload }),
    countries: undefined,
    setCountries: (payload) => set({ countries: payload }),
    mangoCountries: undefined,
    setMangoCountries: (payload) => set({ mangoCountries: payload }),
    mangoNaturalProfile: undefined,
    setMangoNaturalProfile: (payload) => set({ mangoNaturalProfile: payload }),
    mangoLegalProfile: undefined,
    setMangoLegalProfile: (payload) => set({ mangoLegalProfile: payload }),
    connectedAccount: undefined,
    setConnectedAccount: (payload) => set({ connectedAccount: payload }),
    stripeAccountLink: undefined,
    setStripeAccountLink: (payload) => set({ stripeAccountLink: payload }),
    sseErrorMessages: [],
    setSseErrorMessages: (payload) => {
      const messageIndex = get().sseErrorMessages.findIndex(
        (err) => err.type === payload.type
      )
      if (messageIndex >= 0) {
        set({
          sseErrorMessages: get().sseErrorMessages.map((err) =>
            err.type === payload.type ? payload : err
          ),
        })
      } else {
        set({ sseErrorMessages: [...get().sseErrorMessages, payload] })
      }
    },
    clearErrorMessage: (payload) =>
      set({
        sseErrorMessages: get().sseErrorMessages.filter(
          (msg) => msg.type !== payload
        ),
      }),
    cryptoSettings: undefined,
    setCryptoSettings: (payload) => set({ cryptoSettings: payload }),
    stripeSettings: undefined,
    setStripeSettings: (payload) => set({ stripeSettings: payload }),

    //external area
    bankAccounts: [],
    setBankAccounts: (payload) => set({ bankAccounts: payload.list }),
    mangoBankAccounts: [],
    setMangoBankAccounts: (payload) => set({ mangoBankAccounts: payload.accounts }),
    mangoBankAccount: undefined,
    setMangoBankAccount: (payload) => set({ mangoBankAccount: payload }),
    cardAccounts: [],
    setCardAccounts: (payload) => set({ cardAccounts: payload.list }),
    isPendingCancellation: false,
    setIsPendingCancellation: (payload) =>
    set({ isPendingCancellation: payload }),
    mangoUserStatus: UserMangoStatus.uncreated,
    setMangoUserStatus: (payload) => set({ mangoUserStatus: payload }),
    createMangoCardResponse: null,
    setCreateMangoCardResponse: (payload: MangoCardResponse | null) =>
    set({ createMangoCardResponse: payload }),
    mangoKycInfo: null,
    setMangoKycInfo: (payload) => set({ mangoKycInfo: payload }),
    canGoToPayInMango: false,
    setCanGoToPayInMango: (payload) => set({ canGoToPayInMango: payload }),
    payInStatus: MangoPayInStatus.UNKNOWN,
    setPayInStatus: (payload) => set({ payInStatus: payload }),
  }))
)
